import React from 'react';
import { CgDanger } from 'react-icons/cg';

function InputField({
  type = '',
  name = null,
  label = '',
  defaultValue = null,
  onChange = null,
  inputRef = '',
  icon = null,
  error = '',
  isRequired = false,
  textmessage = '',
}) {
  return (
    <>
      <div>
        <div className=''>
          {label && (
            <label
              htmlFor='first'
              className='text-[13px] font-medium text-blue-gray-800 '
            >
              {label}{' '}
              {isRequired ? <font className='text-red-400'>*</font> : <> </>}
            </label>
          )}
        </div>
        <div className={`${label && 'col-span-4'}  `}>
          <div className=' bg-gray-100 relative  border  border-[#01AEEF]  text-[#3a48b2] text-sm rounded  w-full p-0.2 flex justify-center items-center space-x-2 '>
            {textmessage ? (
              <textarea
                required={isRequired}
                defaultValue={defaultValue}
                name={name}
                onChange={onChange}
                type={type ? 'text' : type}
                // ref={inputRef({ required: isRequired })}
                className='text-[13px] font-medium bg-white  border-l-[1px]  border-gray-200  text-gray-600  rounded-r  focus:outline-none   w-full p-[9px]'
              />
            ) : (
              <input
                required={isRequired}
                defaultValue={defaultValue}
                name={name}
                onChange={onChange}
                type={type ? 'text' : type}
                // ref={inputRef({ required: isRequired })}
                className='text-[13px] font-medium bg-white  border-l-[1px]  border-gray-200  text-gray-600  rounded-r  focus:outline-none   w-full p-[9px]'
              />
            )}
            {icon && (
              <div className='flex text-[13px] font-medium justify-center w-[50px] pl-2  text-blue-gray-500 '>
                {icon}
              </div>
            )}
          </div>
          {!error[name] && <div className='py-4'> </div>}
          {error[name] && (
            <div className='text-red-400 text-[12px] font-[500] py-2'>
              <p className='flex items-center '>
                <CgDanger size={13} /> {`${label ? label : name}`} field is
                required
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default InputField;
