import React from 'react';
import { Link } from 'react-router-dom';

const ServiceCard = ({ image, title, subTitle, link }) => {
  return (
    <div>
      <div className='relative flex flex-col justify-between border-[#01AEEF] border-[1px] hover:bg-[#3649B2] hover:text-white group rounded-md text-center px-8  py-10 mb-5  h-[90%] bg-white'>
        <div className='flex justify-center items-start '>
          <div
            className='absolute object-cover border-[1px] border-[#01AEEF] rounded-full bg-white '
            style={{ top: '-30px' }}
          >
            <img
              className='p-3 w-16 h-16'
              src={image}
              alt='serviceone'
            />
          </div>
        </div>
        <h5 className='customHeadingTwo lg:leading-[1.5rem] text-gray-950  text-[0.9rem]  transition-colors group-hover:text-white mt-2'>
          {title}
        </h5>
        <p className='customPeragraph  text-[1rem] text-[#646363] group-hover:text-white my-5 py-2 h-[50%] hyphens-auto  whitespace-pre-line'>
          {subTitle}
        </p>
        <Link
          to={link}
          className='customBtn rounded-full px-5 group-hover:bg-white group-hover:text-[#01AEEF] w-7/12 mx-auto mt-2'
        >
          Read More
        </Link>
      </div>
    </div>
  );
};

export default ServiceCard;
