import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import underLine from '../assets/home/underLine.png';
import advisory from '../assets/service/advisory.png';
import taxvat from '../assets/service/taxvat.png';
import { taxvatserviceData } from '../common/TaxVatServicesData';
const TaxvatAdvisory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        className=' bg-[#E1F0FF] overflow-hidden lg:py-10 '
        style={{
          backgroundImage: `url(${taxvat})`,

          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right',
          backgroundSize: 'contain',
        }}
      >
        <div className='containers lg:flex justify-between items-center  py-20 '>
          <div className='xl:max-w-[50rem] lg:max-w-[35rem]'>
            <h1 className='customHeading text-[#01AEEF]'>
              Navigate Tax And VAT Effortlessly
            </h1>
            <p className='customPeragraph py-5 text-gray-500 '>
              Tax & VAT Advisory: Navigating Compliance, Maximizing Savings
            </p>
            {/* <button className='customBtn'>Discover More</button> */}
          </div>
        </div>
      </div>
      <div className='bg-white overflow-hidden'>
        <div className='containers lg:flex gap-5 py-20 justify-between '>
          <div
            className=''
            // style={{
            //   backgroundImage: `url(${ornament}), url(${ornament})`,
            //   backgroundRepeat: 'no-repeat, no-repeat',
            //   backgroundPosition: 'left top, right bottom',
            //   backgroundSize: 'auto, auto',
            // }}
          >
            <div className='flex justify-center items-center py-5 '>
              <img
                src={advisory}
                alt='about-company'
                className='lg:w-[92%] w-[80%]  object-contain animated-image-right'
              />
            </div>
          </div>
          <div className='lg:max-w-[50%]'>
            <div className='flex justify-center items-center '>
              <div className='text-center lg:w-[90vh]'>
                <h4 className='customHeading text-[#01AEEF] text-[2.2rem]'>
                  Tax & VAT Advisory
                </h4>
                <h1
                  className=' customPeragraph text-center
            '
                >
                  Expert Advisory for Financial Compliance
                </h1>
                <div className='flex justify-center items-center py-5'>
                  <img
                    src={underLine}
                    alt='under-line'
                  />
                </div>
              </div>
            </div>
            <p className='customPeragraph py-8 text-[1rem]'>
              Tax is now the number one regulatory concern of any business, and
              it is driving demand for proper internal control activities and
              tough financial reporting processes to satisfy regulators and
              stakeholders. With exceptional expertise in this area, we offer
              strategic consultancy from the establishment of new entities to
              resolving Tax or VAT conflicts with the Tax & VAT authorities. We
              advise clients on how to achieve their business objectives by
              reducing their Tax & VAT risks and meeting their compliance
              obligations. Our tax consultancy also enhances business and
              operating efficiency as we advise clients on managing their tax
              contributions
            </p>
            <Link to='/contact'>
              {' '}
              <button className='customBtn my-5 px-10'>Contact Us</button>{' '}
            </Link>
            {/* <button className='customBtn'>Read More</button> */}
          </div>
        </div>
      </div>
      {/* table_design */}
      <div
        className='bg-white overflow-hidden'
        id='about_section'
      >
        <div className='containers flex justify-center'>
          <h4 className='customHeading text-[#01AEEF] text-3xl'>
            Our services in this field include, But are not limited to, The
            following
          </h4>
        </div>
        <div className='containers py-20 gap-5  grid lg:grid-cols-4'>
          {taxvatserviceData?.map((item, index) => (
            <span
              key={index}
              className='block max-w-sm p-6 border border-gray-200 rounded-lg shadow bg-[#20b7ee0e] hover:bg-[#20b7ee3f]'
            >
              <h5 className='mb-2 text-[1rem] font-medium  tracking-tight text-slate-500 text-start flex items-center justify-center'>
                {item?.title}
              </h5>
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

export default TaxvatAdvisory;
