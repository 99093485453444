import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import underLine from '../assets/home/underLine.png';
import accounting from '../assets/service/management.svg';
const ManagementConsultancy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contentData = [
    {
      name: 'Strategic Direction',
      content:
        'Our consultancy services provide you with a roadmap for success. Whether you,re navigating a period of growth, facing challenges, or planning for the future, we offer strategic direction that aligns with your business goals.',
    },
    {
      name: 'Operational Efficiency',
      content:
        'Enhance your day-to-day operations with our focus on efficiency. We assess current processes, identify bottlenecks, and implement strategies to streamline operations, maximizing productivity and minimizing costs.',
    },
    {
      name: 'Change Management',
      content:
        'Adapting to change is a constant in business. Our consultants specialize in change management, guiding your organization through transitions, mergers, acquisitions, and other transformative processes with minimal disruption.',
    },
    {
      name: 'Performance Optimization',
      content:
        'Optimizing performance is at the core of our consultancy. We analyse key performance indicators, identify areas for improvement, and work collaboratively to implement solutions that elevate your overall performance.',
    },
  ];

  const offerData = [
    {
      name: 'Strategic Planning',
      content:
        'Crafting a solid strategic plan is fundamental to success. Our consultants work closely with your leadership team to develop a strategic roadmap that aligns with your vision and objectives.',
    },
    {
      name: 'Process Improvement',
      content:
        'Efficiency is key to sustained success. We assess your existing processes, identify inefficiencies, and implement improvements that enhance overall operational effectiveness.',
    },
    {
      name: 'Change Management Strategies',
      content:
        'Navigating change requires a careful approach. We develop and implement change management strategies that minimize resistance, ensuring a smooth transition for your organization.',
    },
    {
      name: 'Performance Metrics and Monitoring',
      content:
        'Understanding how well your business is performing is critical. We establish and monitor key performance indicators, providing you with real-time insights to inform decision-making.',
    },
  ];

  return (
    <>
      <style>
        {`
          @keyframes slideLeftToRight {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(0);
            }
          }
        `}
      </style>
      <div className=' bg-[#E1F0FF] overflow-hidden'>
        <div className='containers lg:flex justify-between items-center  py-20'>
          <div className='lg:max-w-[50%]'>
            <h1 className='customHeading'>Management Consultancy</h1>
            <p className='customPeragraph py-8'>
              In the dynamic landscape of business, strategic management is the
              compass that guides organizations to success. Our "Management
              Consultancy" service at Company Care & Associates is crafted to
              empower businesses with tailored solutions, strategic insights,
              and operational excellence.
            </p>
            {/* <button className='customBtn'>Discover More</button> */}
          </div>
          <div className='flex justify-center'>
            <img
              className='lg:w-4/5 md:w-2/4 w-3/4 object-contain animated-image'
              src={accounting}
              alt='cover-image'
            />
          </div>
        </div>
      </div>
      <div className='bg-[#EFF7FF] overflow-hidden'>
        <div className='containers lg:flex gap-5 pt-20 justify-between '>
          <div className=''>
            <div className='flex justify-center items-center '>
              <div className='text-center lg:w-[90vh]'>
                <h4 className='customHeading text-[#01AEEF]'>
                  Why Choose Management Consultancy{' '}
                </h4>

                <div className='flex justify-center items-center py-5'>
                  <img
                    src={underLine}
                    alt='under-line'
                  />
                </div>
              </div>
            </div>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-10 py-5'>
              {contentData?.map((item, index) => (
                <div className='bg-[#EFF7FF] py-5 px-5 rounded-md border-y-2 border-[#8fcee5] '>
                  <p className='customPeragraph py-8 font-medium'>
                    {item?.name}
                  </p>
                  <p className='text-base'>{item?.content}</p>
                </div>
              ))}
            </div>
            {/* <button className='customBtn'>Read More</button> */}
          </div>
        </div>
      </div>
      {/* What We Offer */}
      <div className='bg-[#EFF7FF] overflow-hidden'>
        <div className='containers lg:flex gap-5 py-20 justify-between '>
          <div className=''>
            <div className='flex justify-center items-center '>
              <div className='text-center lg:w-[90vh]'>
                <h4 className='customHeading text-[#01AEEF]'>
                What We Offer{' '}
                </h4>

                <div className='flex justify-center items-center py-5'>
                  <img
                    src={underLine}
                    alt='under-line'
                  />
                </div>
              </div>
            </div>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-10 py-5'>
              {offerData?.map((item, index) => (
                <div className='bg-sky-100 p-7 rounded-md  '>
                  <p className='customPeragraph text-left py-3 font-medium'>
                    {item?.name}
                  </p>
                  <p className='text-base text-gray-800'>{item?.content}</p>
                </div>
              ))}
            </div>
            <Link to='/contact' className='flex justify-center'>
              {' '}
              <button className='customBtn my-8 px-10'>Contact Us</button>{' '}
            </Link>
            {/* <button className='customBtn'>Read More</button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagementConsultancy;
