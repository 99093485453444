import React from 'react';
import company_manage from '../assets/home/company_manage.png';
import { Link } from 'react-router-dom';
const AboutCompany = () => {
  return (
    <div
      className='bg-[#EFF7FF] overflow-hidden'
      id='about_section'
    >
      <div className='containers lg:flex gap-10 py-20 justify-between '>
        <div className='flex justify-center items-center py-5 '>
          <img
            src={company_manage}
            alt='about-company'
            className='lg:w-[92%]  object-contain animated-image-left'
          />
        </div>

        <div className='lg:max-w-[50%]'>
          <h4 className='customHeadingTwo'>About Company Care & Associates</h4>
          <h1 className='customHeading'>
            Company Care Allows You Manage & Grow Your Business
          </h1>
          <p className='customPeragraph py-8'>
            Company Care & Associates is your dedicated partner in financial
            excellence. Since 2020, we have been at the forefront of providing
            top-quality accounting, costing, consulting, internal auditing, Tax
            and VAT advisory services to businesses and individuals in a timely
            and cost effective process in Bangladesh & overseas. Our commitment
            to excellence, innovation, and client-focused solutions sets us
            apart.
          </p>
          <Link to="/about"><button className='customBtn'>Read More</button></Link>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
