import React, { useEffect } from 'react';
import underLine from '../assets/home/underLine.png';
import { servicesData } from '../common/servicesData';
import ServiceCard from './ServiceCard';
const AllServiceCard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className='bg-[#E1F0FF]'
      id='services'
    >
      <div className='containers py-20'>
        <div className='flex justify-center items-center'>
          <div className='text-center'>
            <h4 className='customHeadingTwo'>
              Our Trusted Excellence in Global Business
            </h4>
            <h1 className='customHeading '>
              We Provide Wide Range of Business Services
            </h1>
            <div className='flex justify-center items-center py-5'>
              <img
                src={underLine}
                alt='under-line'
              />
            </div>
          </div>
        </div>
        <div className='grid lg:grid-cols-3 gap-10 py-20'>
          {servicesData?.map((item, index) => (
            <ServiceCard
              key={index}
              image={item?.img}
              link={item?.link}
              title={item?.title}
              subTitle={item?.sutTitle}
            />
          ))}
        </div>
        {/* <div className='flex justify-center '>
          <Link to='/all-services'>
            {' '}
            <button className='customBtn my-5 px-10'>View All</button>{' '}
          </Link>
        </div> */}
      </div>
      <hr className='bg-slate-50 h-[2px]' />
    </div>
  );
};

export default AllServiceCard;
