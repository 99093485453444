import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/home/logo.png';
import { headerdata } from '../common/headerData';
const NavbarSection = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleTestComponents = () => {
    setIsOpen(!isOpen);
  };
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className=' py-2 border-b-2 border-[#E1F0FF] sticky top-0 bg-white z-50'>
      <div className='containers'>
        <div className='mx-auto  uppercase font-medium'>
          <div className=''>
            <div className='flex items-center justify-between '>
              <div className='lg:w-[10%]'>
                <NavLink
                  to='/'
                  onClick={() => scrollToSection('home')}
                >
                  <img
                    src={logo}
                    alt='logo'
                    className='w-40 object-contain'
                  />
                </NavLink>
              </div>
              <div className='hidden lg:block w-[90%]'>
                <div className=' flex items-baseline justify-center space-x-4 '>
                  {headerdata.map((data, index) => (
                    <NavLink
                      key={index}
                      style={({ isActive, isPending }) => {
                        return {
                          color: isActive ? '#01AEEF' : 'black',
                          background: isActive ? 'transparent' : 'transparent',
                        };
                      }}
                      className=' text-gray-300 px-3 py-2 rounded-md xl:text-sm text-[10px] font-semibold hover:bg-sky-700'
                      to=''
                    >
                      <button
                        onClick={() => scrollToSection(data.to)}
                        className='uppercase'
                      >
                        {data.item}
                      </button>
                    </NavLink>
                  ))}
                </div>
              </div>
              <div className='-mr-2 flex lg:hidden '>
                <button
                  type='button'
                  className='bg-[#01AEEF] inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white '
                  aria-controls='mobile-menu'
                  aria-expanded={isOpen}
                  onClick={toggleTestComponents}
                >
                  <span className='sr-only'>Open main menu</span>
                  {/* Heroicon name: menu */}
                  <svg
                    className={`block h-6 w-6 ${isOpen ? 'hidden' : ''}`}
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M4 6h16M4 12h16M4 18h16'
                    ></path>
                  </svg>
                  {/* Heroicon name: x */}
                  <svg
                    className={`h-6 w-6 ${isOpen ? '' : 'hidden'}`}
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M6 18L18 6M6 6l12 12'
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`lg:hidden ${isOpen ? '' : 'hidden'}`}
          id='mobile-menu'
        >
          <div className='px-2 pt-2 pb-3 space-y-1 sm:px-3 uppercase font-medium text-center'>
            {headerdata.map((data, index) => (
              <NavLink
                key={index}
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'normal' : 'normal',
                    color: isActive ? '#01AEEF' : 'black',
                    background: isActive ? 'transparent' : 'transparent',
                  };
                }}
                onClick={toggleTestComponents}
                className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition-all duration-500'
                to=''
              >
                <button
                  onClick={() => scrollToSection(data.to)}
                  className='uppercase'
                >
                  {data.item}
                </button>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavbarSection;
