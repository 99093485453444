import React from 'react';

const WorkCard = ({ image, title, content }) => {
  return (
    <div className='mx-2'>
      <div className=''>
        <div className='flex justify-center items-center '>
          <img
            className='bg-white rounded-xl p-2  object-contain max-w-[20%] '
            src={image}
            alt='workIcon'
          />
        </div>
        <h2 className='customHeadingTwo leading-[1.5rem] text-gray-950 my-4 text-[1rem] transition-colors group-hover:text-white text-center'>
          {title}
        </h2>
        <p className='customPeragraph text-center text-[1rem] text-[#646363] px-2'>
          {content}
        </p>
      </div>
    </div>
  );
};

export default WorkCard;
