import business from "../assets/home/business.png";
import market from "../assets/home/market.png";
import serviceIcon1 from "../assets/home/serviceIcon1.png";
import serviceIcon2 from "../assets/home/serviceIcon2.png";
import serviceIcon3 from "../assets/home/serviceIcon3.png";
import serviceIcon4 from "../assets/home/serviceIcon4.png";
import Financial from "../assets/service/financialimage.png";
import fundmanagement from "../assets/service/fundmanagement.png";
import Internal from "../assets/service/internal.png";
import management from "../assets/service/fundimg.png";
import Raising from "../assets/service/raising.png";
import RJSC from "../assets/service/rjsc.png";
export const servicesData = [
  {
    img: serviceIcon1,
    title: "Accounting & Compilation",
    sutTitle:
      "Company Care & Associates offers individuals and businesses a variety of accounting services tailored to their special needs",
    link: "/accounting-compilation",
  },
  {
    img: serviceIcon2,
    title: "Outsourced CFO Services",
    sutTitle:
      "While most small and mid-sized businesses in Bangladesh recognize the value of high-caliber finance professional in-house...",
    link: "/outsourced-CFO",
  },
  {
    img: serviceIcon3,
    title: "TAX & VAT Advisory",
    sutTitle:
      "Tax is now the number one regulatory concern of any business, and it is driving demand for proper internal Planning activities..",
    link: "/taxVat-advisory",
  },
  {
    img: serviceIcon4,
    title: "Product Costing",
    sutTitle:
      "Precision in Profitability. Our expert analysis ensures accurate assessment and optimization of production costs...",
    link: "/product-costing",
  },
  {
    img: business,
    title: "Business Plans / Business Valuations",
    sutTitle:
      "We specialize in developing strategic business plans that chart your course to success. Additionally, our valuation expertise helps you understand..",
    link: "/business-plan",
  },
  {
    img: market,
    title: "Market Research & Feasibility Studies",
    sutTitle:
      "Harness the power of data and analysis to evaluate market potential and project viability. Our expert research team equips..",
    link: "/marketResearch-feasibility",
  },
  {
    img: Internal,
    title: "Internal Audit Implementation & Assurance",
    sutTitle:
      "We ensures robust internal audit systems and provides assurance on compliance, risk management, and operational efficiency.",
    link: "/internal-audit-implementation",
  },

  {
    img: RJSC,
    title: "RJSC Advisory",
    sutTitle:
      "We assist in navigating the complex and unique regulatory Documentation, ensuring timeliness registration, compliance..",
    link: "/rjsc-advisory",
  },
  {
    img: Raising,
    title: "Raising Bank Finance",
    sutTitle:
      "We facilitate securing the necessary capital from financial institutions, Empower your business expansion and projects with our expertise..",
    link: "/raising-bank-finance",
  },
  {
    img: Financial,
    title: "Financial & commercial Due Diligence",
    sutTitle:
      "Managing Risks. Our thorough examination and analysis provide a clear picture of financial health and commercial viability, ",
    link: "/financial-and-commercial-diligence",
  },
  {
    img: management,
    title: "Management Consultancy",
    sutTitle:
      "We offer expert insights and strategies to optimize your organization,s performance, streamline operations, and foster growth. ",
    link: "/management-consultancy",
  },
  {
    img: fundmanagement,
    title: "Fund Management",
    sutTitle:
      "Our skilled fund managers work diligently to optimize investments, balancing risk and returns. Trust us to safeguard and enhance your.. ",
    link: "/fund-management",
  },
];
