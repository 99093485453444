import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import underLine from "../assets/home/underLine.png";
import accounting from "../assets/service/deligencebanner.svg";
const FinancialDiligence = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contentData = [
    {
      name: "Risk Mitigation",
      content:
        "Uncover potential risks and liabilities before they become detrimental. Our due diligence process meticulously examines financial and commercial aspects, helping you make informed decisions that safeguard your business.",
    },
    {
      name: "Informed Decision-Making",
      content:
        "Whether you,re entering into mergers, acquisitions, or partnerships, having a clear understanding of the financial health and commercial viability of the entities involved is paramount. Our due diligence services empower you with the knowledge needed for strategic decision-making.",
    },
    {
      name: "Investment Confidence",
      content:
        "For investors considering a new venture, due diligence is the key to instilling confidence. Our thorough assessments provide a detailed overview of the target company,s financial performance, commercial viability, and growth potential.",
    },
    {
      name: "Compliance Assurance",
      content:
        "Ensure that the entities you engage with adhere to regulatory and commercial standards. Our due diligence process includes a comprehensive review of compliance, identifying any potential areas of concern.",
    },
  ];

  const offerData = [
    {
      name: "Financial Analysis",
      content:
        "Our team conducts a detailed analysis of financial statements, cash flows, and other relevant financial metrics, providing you with a clear picture of the target company's fiscal health.",
    },
    {
      name: "Commercial Viability Assessment",
      content:
        "Beyond financials, we assess the commercial aspects of a business, including market positioning, customer relationships, and competitive advantages.",
    },
    {
      name: "Risk Evaluation",
      content:
        "Identifying and evaluating potential risks is a cornerstone of our service. We provide a thorough risk assessment, offering insights into areas that may impact the success of the transaction.",
    },
    {
      name: "Customized Reports and Recommendations",
      content:
        "Our due diligence reports are not just summaries; they are comprehensive documents that include detailed findings, potential challenges, and actionable recommendations.",
    },
  ];

  return (
    <>
      <style>
        {`
          @keyframes slideLeftToRight {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(0);
            }
          }
        `}
      </style>
      <div className=" bg-[#E1F0FF] overflow-hidden">
        <div className="containers lg:flex justify-between items-center  py-20">
          <div className="lg:max-w-[50%]">
            <h1 className="customHeading">
              Financial & commercial Due Diligence
            </h1>
            <p className="customPeragraph py-8">
              Embarking on significant business transactions requires a thorough
              understanding of the financial and commercial landscape. Our
              "Financial & Commercial Due Diligence" service at Company Care &
              Associates is designed to provide you with comprehensive insights,
              mitigating risks, and ensuring informed decision-making.
            </p>
            {/* <button className='customBtn'>Discover More</button> */}
          </div>
          <div className="flex justify-center">
            <img
              className="lg:w-4/5 md:w-2/4 w-3/4 object-contain animated-image"
              src={accounting}
              alt="cover-image"
            />
          </div>
        </div>
      </div>
      <div className="bg-[#EFF7FF] overflow-hidden">
        <div className="containers lg:flex gap-5 pt-20 justify-between ">
          <div className="">
            <div className="flex justify-center items-center ">
              <div className="text-center lg:w-[90vh]">
                <h4 className="customHeading text-[#01AEEF]">
                  Why due Diligence Matters
                </h4>

                <div className="flex justify-center items-center py-5">
                  <img src={underLine} alt="under-line" />
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-10 py-5">
              {contentData?.map((item, index) => (
                <div className=" p-8 rounded-md border-r-2 border-[#01AEEF] ">
                  <p className="customPeragraph pb-4 font-medium">
                    {item?.name}
                  </p>
                  <p className="text-base">{item?.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* What We Offer */}
      <div className="bg-[#EFF7FF] overflow-hidden">
        <div className="containers lg:flex gap-5 py-20 justify-between ">
          <div className="">
            <div className="flex justify-center items-center ">
              <div className="text-center lg:w-[90vh]">
                <h4 className="customHeading text-[#01AEEF]">What We Offer</h4>

                <div className="flex justify-center items-center py-5">
                  <img src={underLine} alt="under-line" />
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-10 py-5">
              {offerData?.map((item, index) => (
                <div className="p-10 rounded-md shadow shadow-slate-300 ">
                  <p className="text-[#01AEEF] lg:text-[1.1rem] text-[1rem] pb-4 font-medium">
                    {item?.name}
                  </p>
                  <p className="text-base">{item?.content}</p>
                </div>
              ))}
            </div>
            <Link to="/contact" className="flex justify-center">
              {" "}
              <button className="customBtn my-8 px-10">Contact Us</button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialDiligence;
