import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import underLine from '../assets/home/underLine.png';
import accounting from '../assets/service/rjscbanner.svg';
const RjscAdvisory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contentData = [
    {
      name: 'Regulatory Compliance',
      content:
        'Staying compliant with RJSC regulations is a cornerstone of a well-managed business. Our expert team ensures that your company adheres to all necessary statutory requirements, filings, and updates.',
    },
    {
      name: 'Incorporation Assistance',
      content:
        'Starting a new venture? Our RJSC Advisory service provides end-to-end assistance in the company incorporation process. From document preparation to submission, we streamline the process for a hassle-free start to your business journey.',
    },
    {
      name: 'Annual Compliance Review',
      content:
        'Annual compliance is not just a formality. it,s a strategic necessity. We conduct comprehensive reviews to ensure that your company meets all annual compliance requirements, minimizing the risk of penalties and legal complications.',
    },
    {
      name: 'Document Preparation and Filing',
      content:
        'Our team assists in the meticulous preparation and filing of documents required by the RJSC. From annual reports to changes in directorship, we handle the paperwork, allowing you to focus on your core business activities.',
    },
  ];

  return (
    <>
      <style>
        {`
          @keyframes slideLeftToRight {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(0);
            }
          }
        `}
      </style>
      <div className=' bg-[#E1F0FF] overflow-hidden'>
        <div className='containers lg:flex justify-between items-center  py-20'>
          <div className='lg:max-w-[50%]'>
            <h1 className='customHeading'>RJSC Advisory</h1>
            <p className='customPeragraph py-8'>
              Navigating the regulatory landscape is crucial for the smooth
              operation and compliance of your business. Our "RJSC Advisory"
              service at Company Care & Associates is designed to provide
              comprehensive guidance on matters related to the Registrar of
              Joint Stock Companies and Firms (RJSC) in Bangladesh.
            </p>
            {/* <button className='customBtn'>Discover More</button> */}
          </div>
          <div className='flex justify-center'>
            <img
              className='lg:w-4/5 md:w-2/4 w-3/4 object-contain animated-image'
              src={accounting}
              alt='cover-image'
            />
          </div>
        </div>
      </div>
      <div className='bg-[#EFF7FF] overflow-hidden'>
        <div className='containers lg:flex gap-5 py-20 justify-between '>
          <div className=''>
            <div className='flex justify-center items-center '>
              <div className='text-center lg:w-[90vh]'>
                <h4 className='customHeading text-[#01AEEF]'>Our Expertise</h4>

                <div className='flex justify-center items-center py-5'>
                  <img
                    src={underLine}
                    alt='under-line'
                  />
                </div>
              </div>
            </div>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-10 py-5'>
              {contentData?.map((item, index) => (
                <div className='bg-sky-100 p-10 rounded-md border-x-2 border-zinc-600'>
                  <p className='customPeragraph pb-4 font-medium'>
                    {item?.name}
                  </p>
                  <p className='text-base'>{item?.content}</p>
                </div>
              ))}
            </div>
            <Link to='/contact' className='flex justify-center'>
              {' '}
              <button className='customBtn my-8 px-10'>Contact Us</button>{' '}
            </Link>
            {/* <button className='customBtn'>Read More</button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default RjscAdvisory;
