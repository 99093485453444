import React from 'react';
import cover_image from '../assets/home/cover_image.png';
const CoverSection = () => {
  return (
    <>
      <style>
        {`
          @keyframes slideLeftToRight {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(0);
            }
          }
        `}
      </style>
      <div
        className=' bg-[#E1F0FF] overflow-hidden'
        id='home'
      >
        <div className='containers lg:flex justify-between items-center gap-10  py-20'>
          <div className='lg:max-w-[50%]'>
            <h1 className='customHeading'>
              Accounts & Finance Has Everything Thats You Need
            </h1>
            <p className='customPeragraph py-8'>
              Welcome to Company Care & Associates: Empowering Your Financial
              Success. Explore our comprehensive accounting, costing,
              consulting, internal auditing, and Tax advisory services tailored
              to businesses and individuals. Let us guide you towards a
              prosperous future.
            </p>
            <button className='customBtn'>Discover More</button>
          </div>
          <div className='flex justify-center mt-10 lg:mt-0'>
            <img
              className='lg:w-[92%] w-[80%]  object-contain animated-image'
              src={cover_image}
              alt='cover-image'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverSection;
