import React from 'react';
import AboutCompany from '../components/AboutCompany';
import ConsultantSection from '../components/ConsultantSection';
import ContactSection from '../components/ContactSection';
import CoverSection from '../components/CoverSection';
import ServiceSection from '../components/ServiceSection';
import WorkPage from '../components/WorkPage';

const HomeScreen = () => {
  return (
    <div>
      <CoverSection />
      <AboutCompany />
      <ServiceSection />
      <WorkPage />
      <ConsultantSection />
      {/* <ProjectStaticsSection /> */}
      <ContactSection />
    </div>
  );
};

export default HomeScreen;
