export const headerdata = [
  {
    id: 1,
    item: 'home',
    to: 'home',
  },
  {
    id: 2,
    item: 'about Us',
    to: 'about_section',
  },
  {
    id: 3,
    item: 'Services',
    to: 'services',
  },

  {
    id: 4,
    item: 'Consultant',
    to: 'consultant',
  },

  {
    id: 5,
    item: 'Contact Us',
    to: 'contact',
  },
];
