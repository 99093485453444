import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import underLine from '../assets/home/underLine.png';
import market from '../assets/service/market.png';
import market2 from '../assets/service/market2.png';
const MarketResearch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        className=' bg-[#E1F0FF] overflow-hidden lg:py-20'
        style={{
          backgroundImage: `url(${market})`,

          backgroundRepeat: 'no-repeat',
          //   backgroundPosition: 'top right',
          backgroundSize: 'cover',
        }}
      >
        <div className='containers lg:flex justify-center items-center  py-32'>
          <div className='xl:max-w-[60rem] lg:max-w-[35rem]'>
            <h1 className='customHeading text-white text-center'>
              Unveiling Opportunities, Mitigating Risks and Guiding Strategic
              Growth
            </h1>
            {/* <p className='customPeragraph py-8 text-gray-300'>
              Tax & VAT Advisory: Navigating Compliance, Maximizing Savings
            </p> */}
            {/* <button className='customBtn'>Discover More</button> */}
          </div>
        </div>
      </div>
      <div className='bg-white overflow-hidden'>
        <div className='containers lg:flex gap-5 pt-10 justify-between '>
          <div
            className=''
            // style={{
            //   backgroundImage: `url(${ornament}), url(${ornament})`,
            //   backgroundRepeat: 'no-repeat, no-repeat',
            //   backgroundPosition: 'left top, right bottom',
            //   backgroundSize: 'auto, auto',
            // }}
          >
            <div className='flex justify-center items-center py-5 '>
              <img
                src={market2}
                alt='about-company'
                className='lg:w-[92%] w-[80%] object-contain animated-image-right'
              />
            </div>
          </div>
          <div className='xl:max-w-[45rem] lg:max-w-[35rem]'>
            <div className='flex justify-center items-center '>
              <div className='text-center lg:w-[90vh]'>
                <h4 className='customHeading text-[#01AEEF] text-[2.2rem]'>
                  Market Research & Feasibility Studies
                </h4>
                <h1
                  className=' customPeragraph text-center
            '
                >
                  Navigate Markets, Seize Opportunities, Make Informed Moves.
                </h1>
                <div className='flex justify-center items-center py-5'>
                  <img
                    src={underLine}
                    alt='under-line'
                  />
                </div>
              </div>
            </div>
            <p className='customPeragraph py-8 text-base'>
              Harness the power of data and analysis to evaluate market
              potential and project viability. Our expert research team equips
              you with comprehensive insights, enabling strategic decisions and
              successful ventures.
            </p>
            <Link to='/contact'>
              {' '}
              <button className='customBtn my-5 px-10'>Contact Us</button>{' '}
            </Link>
          </div>
        </div>
      </div>
      <div className='containers lg:flex justify-between items-center  py-20'>
        <div>
          <div>
            <p className='customPeragraph py-8 font-medium'>
              Navigating Market Dynamics
            </p>
            <p className='text-base'>
              In a rapidly evolving market landscape, understanding consumer
              behavior, industry trends, and competitive positioning is
              paramount. Our Market Research & Feasibility Studies delve deep
              into these dynamics, providing you with a clear roadmap to
              navigate and succeed in your target market.
            </p>
          </div>
          <div>
            <p className='customPeragraph py-8 font-medium'>
              Unveiling Opportunities
            </p>
            <p className='text-base'>
              Identifying growth opportunities and untapped markets is a
              cornerstone of our service. We unveil the most promising prospects
              for your business, allowing you to make well-informed choices and
              focus your efforts where they matter most.
            </p>
          </div>
          <div>
            <p className='customPeragraph py-8 font-medium'>
              Mitigating Risks, Maximizing Gains
            </p>
            <p className='text-base'>
              Business ventures inherently carry risks. However, with our
              rigorous feasibility studies and risk assessment, we help you
              anticipate potential challenges and develop risk mitigation
              strategies. This proactive approach ensures your ventures are
              primed for success while minimizing adverse outcomes.
            </p>
          </div>
          <div>
            <p className='customPeragraph py-8 font-medium'>
              Strategic Growth Roadmap
            </p>
            <p className='text-base'>
              Our studies aren't just reports; they're your growth roadmap. We
              provide actionable recommendations and strategic insights that
              empower you to make the right moves at the right time, steering
              your business towards sustainable growth and long-term prosperity.
              Leverage the strength of knowledge and data. Choose Company Care &
              Associates for comprehensive Market Research & Feasibility Studies
              that elevate your business strategies.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketResearch;
