import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import underLine from "../assets/home/underLine.png";
import accounting from "../assets/service/accounting.png";
const InternalAudit = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <style>
        {`
          @keyframes slideLeftToRight {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(0);
            }
          }
        `}
      </style>
      <div className=" bg-[#E1F0FF] overflow-hidden">
        <div className="containers lg:flex justify-between items-center  py-20">
          <div className="lg:max-w-[50%]">
            <h1 className="customHeading">
              Internal Audit Implementation & Assurance
            </h1>
            <p className="customPeragraph py-8">
              At Company Care & Associates, we understand the critical role that
              internal audit plays in ensuring the integrity and efficiency of
              your business operations. Our "Internal Audit Implementation &
              Assurance" service is personalized to strengthen your
              organization's core, providing comprehensive solutions to enhance
              governance, compliance, and overall performance.
            </p>
            {/* <button className='customBtn'>Discover More</button> */}
          </div>
          <div className="flex justify-center">
            <img
              className="lg:w-4/5 md:w-2/4 w-3/4 object-contain animated-image"
              src={accounting}
              alt="cover-image"
            />
          </div>
        </div>
      </div>
      <div className="bg-[#EFF7FF] overflow-hidden">
        <div className="containers lg:flex gap-5 py-20 justify-between ">
          <div className="">
            <div className="flex justify-center items-center ">
              <div className="text-center lg:w-[90vh]">
                <h4 className="customHeading text-[#01AEEF]">
                  What Sets Us Apart
                </h4>

                <div className="flex justify-center items-center py-5">
                  <img src={underLine} alt="under-line" />
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-10 py-5">
              <div className="bg-sky-100 py-5 px-5 rounded-md ">
                <p className="customPeragraph py-8 font-medium">
                  Robust Internal Audit Systems
                </p>
                <p className="text-base">
                  We specialize in the design and implementation of robust
                  internal audit systems custom-made to your organizational
                  structure. Our goal is to create a framework that not only
                  meets regulatory standards but also adds value by identifying
                  operational efficiencies and mitigating risks.
                </p>
              </div>
              <div className="bg-sky-100 py-5 px-5 rounded-md">
                <p className="customPeragraph py-8 font-medium">
                  Compliance Assurance
                </p>
                <p className="text-base">
                  Navigating the complex landscape of regulatory compliance can
                  be challenging. Our expert team ensures that your internal
                  audit processes align flawlessly with industry regulations,
                  providing assurance and peace of mind.
                </p>
              </div>
              <div className="bg-sky-100 py-5 px-5 rounded-md">
                <p className="customPeragraph py-8 font-medium">
                  Risk Management Excellence
                </p>
                <p className="text-base">
                  Identifying and managing risks is at the heart of our service.
                  Through meticulous risk assessments, we help safeguard your
                  organization against potential threats, ensuring resilience in
                  the face of uncertainties.
                </p>
              </div>
              <div className="bg-sky-100 py-5 px-5 rounded-md">
                <p className="customPeragraph py-8 font-medium">
                  Operational Efficiency Enhancement
                </p>
                <p className="text-base">
                  Our approach goes beyond compliance; we aim to enhance your
                  operational efficiency. By identifying inefficiencies and
                  recommending process improvements, we contribute to the
                  overall effectiveness of your business operations.
                </p>
              </div>
            </div>
            <Link to="/contact" className="flex justify-center">
              {" "}
              <button className="customBtn my-8 px-10">Contact Us</button>{" "}
            </Link>
            {/* <button className='customBtn'>Read More</button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default InternalAudit;
