import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import underLine from '../assets/home/underLine.png';
import accounting from '../assets/service/fund.svg';
const FundManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contentData = [
    {
      name: 'Strategic Allocation',
      content:
        'Strategically allocating funds is crucial for maximizing returns and minimizing risks. Our fund management services focus on allocating resources in a way that aligns with your business objectives and risk tolerance.',
    },
    {
      name: 'Investment Optimization',
      content:
        'Unlock the full potential of your investments with our optimization strategies. We assess market trends, evaluate investment opportunities, and implement strategies that enhance the overall performance of your investment portfolio.',
    },
    {
      name: 'Risk Mitigation',
      content:
        'Every investment carries inherent risks. Our fund management approach includes rigorous risk assessments and mitigation strategies to safeguard your financial assets and ensure long-term stability.',
    },
    {
      name: 'Financial Health Enhancement',
      content:
        'A well-managed fund is the cornerstone of a healthy financial profile. We work towards enhancing your overall financial health by ensuring that funds are deployed strategically and generate optimal returns.',
    },
  ];

  const offerData = [
    {
      name: 'Customized Fund Strategies',
      content:
        'Our team develops customized fund management strategies personalized to your business goals, financial standing, and risk appetite.',
    },
    {
      name: 'Investment Portfolio Analysis',
      content:
        'We conduct a thorough analysis of your investment portfolio, identifying areas for improvement and opportunities for diversification.',
    },
    {
      name: 'Risk Assessment and Mitigation',
      content:
        'Our experts assess potential risks associated with your investments and implement strategies to mitigate those risks, ensuring a more secure financial future.',
    },
    {
      name: 'Performance Monitoring',
      content:
        'Regular monitoring is essential for successful fund management. We provide ongoing performance analysis, ensuring that your funds are consistently meeting or exceeding expectations.',
    },
  ];

  return (
    <>
      <style>
        {`
          @keyframes slideLeftToRight {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(0);
            }
          }
        `}
      </style>
      <div className=' bg-[#E1F0FF] overflow-hidden'>
        <div className='containers lg:flex justify-between items-center  py-20'>
          <div className='lg:max-w-[50%]'>
            <h1 className='customHeading'>Fund Management</h1>
            <p className='customPeragraph py-8'>
              Effectively managing funds is not just a financial necessity; it's
              the backbone of sustained business growth. Our "Fund Management"
              service at Company Care & Associates is tailored to optimize your
              financial resources, enhance investment strategies, and ensure the
              financial health of your business.
            </p>
            {/* <button className='customBtn'>Discover More</button> */}
          </div>
          <div className='flex justify-center'>
            <img
              className='lg:w-4/5 md:w-2/4 w-3/4 object-contain animated-image'
              src={accounting}
              alt='cover-image'
            />
          </div>
        </div>
      </div>
      <div className='bg-[#EFF7FF] overflow-hidden'>
        <div className='containers lg:flex gap-5 pt-20 justify-between '>
          <div className=''>
            <div className='flex justify-center items-center '>
              <div className='text-center lg:w-[90vh]'>
                <h4 className='customHeading text-[#01AEEF]'>
                  Why Fund Management Matters
                </h4>

                <div className='flex justify-center items-center py-5'>
                  <img
                    src={underLine}
                    alt='under-line'
                  />
                </div>
              </div>
            </div>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-10 py-5'>
              {contentData?.map((item, index) => (
                <div className='bg-sky-100 p-10 rounded-md border-l-2 border-[#01AEEF] '>
                  <p className='customPeragraph pb-3 font-medium '>
                    {item?.name}
                  </p>
                  <p className='text-base '>{item?.content}</p>
                </div>
              ))}
            </div>
            {/* <button className='customBtn'>Read More</button> */}
          </div>
        </div>
      </div>
      {/* What We Offer */}
      <div className='bg-[#EFF7FF] overflow-hidden'>
        <div className='containers lg:flex gap-5 py-20 justify-between '>
          <div className=''>
            <div className='flex justify-center items-center '>
              <div className='text-center lg:w-[90vh]'>
                <h4 className='customHeading text-[#01AEEF]'>
                What We Offer
                </h4>

                <div className='flex justify-center items-center py-5'>
                  <img
                    src={underLine}
                    alt='under-line'
                  />
                </div>
              </div>
            </div>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-8 py-5'>
              {offerData?.map((item, index) => (
                <div className='bg-white p-8 rounded-md border-x-2 border-[#a3daee] '>
                  <p className='customPeragraph pb-4 font-medium text-[#01AEEF]'>
                    {item?.name}
                  </p>
                  <p className='text-base'>{item?.content}</p>
                </div>
              ))}
            </div>
            <Link to='/contact' className='flex justify-center'>
              {' '}
              <button className='customBtn my-8 px-10'>Contact Us</button>{' '}
            </Link>
            {/* <button className='customBtn'>Read More</button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FundManagement;
