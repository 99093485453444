import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import underLine from '../assets/home/underLine.png';
import cfoservice from '../assets/service/cfoservice.png';
import outsource from '../assets/service/outsource.png';
const OutsourcedServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <style>
        {`
          @keyframes slideLeftToRight {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(0);
            }
          }
        `}
      </style>
      <div
        className=' bg-[#E1F0FF] overflow-hidden '
        style={{
          backgroundImage: `url(${cfoservice})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top',
          backgroundSize: 'auto',
        }}
      >
        <div className='containers lg:flex justify-between items-center  py-20'>
          <div className='xl:max-w-[50rem] lg:max-w-[35rem]'>
            <h1 className='customHeading text-white'>
              Elevate Your Finances: Accounting & Compilation Excellence
            </h1>
            <p className='customPeragraph py-8 text-gray-300'>
              Discover precision in financial records with our Accounting &
              Compilation Services. Trust our experts to organize, streamline,
              and optimize your financial data, empowering your business to
              reach new heights.
            </p>
          </div>
        </div>
      </div>
      <div className='containers lg:flex gap-5  justify-between pt-10'>
        <div className='flex justify-center items-center '>
          <img
            src={outsource}
            alt='about-company'
            className='lg:w-[75%]  object-contain animated-image-left'
          />
        </div>
        <div className='lg:max-w-[50%] '>
          <div className='flex justify-center items-center '>
            <div className='text-center lg:w-[80%] '>
              <h4 className='customHeading text-[#01AEEF] text-[2.2rem]'>
                Outsourced CFO Services
              </h4>
              <h1
                className=' customPeragraph text-center
            '
              >
                Elevate Your Financial Strategy with Outsourced CFO Expertise
              </h1>
              <div className='flex justify-center items-center py-5'>
                <img
                  src={underLine}
                  alt='under-line'
                />
              </div>
            </div>
          </div>
          <p className='customPeragraph py-8 text-[1rem]'>
            While most small and mid-sized businesses in Bangladesh recognize
            the value of high-caliber finance professional in-house, there are
            few that can afford such a resource. With our CFO services you can
            get both options: skill sets of an experienced CFO, and custom -
            tailored CFO services – at a fraction of the price. Company Care &
            Associates provides financial professionals with extensive
            experience in the CFO environment who are results-driven business
            partners. Value creation is a primary goal of these part-time CFO's
            who provide financial oversight, funding assistance, and management
            direction. Our CFO's have a solid background in management and
            financial guidance at all levels.
          </p>
          <Link to='/contact'>
            {' '}
            <button className='customBtn my-5 px-10'>Contact Us</button>{' '}
          </Link>
          {/* <button className='customBtn'>Read More</button> */}
        </div>
      </div>
    </>
  );
};

export default OutsourcedServices;
