import React from 'react';
import error from '../assets/home/404png';

const NotFoundScreen = () => {
  return (
    <>
      <div className='flex items-center  justify-center my-20'>
        <img
          src={error}
          alt=''
          className='w-[50%]'
        />
      </div>
    </>
  );
};

export default NotFoundScreen;
