import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import underLine from '../assets/home/underLine.png';
import business from '../assets/service/business.png';
import business2 from '../assets/service/business2.png';
import business3 from '../assets/service/business3.png';
const BusinessPlan = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        className=' bg-[#E1F0FF] overflow-hidden lg:py-10'
        style={{
          backgroundImage: `url(${business})`,

          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom left',
          backgroundSize: 'contain',
        }}
      >
        <div className='containers lg:flex justify-end items-center  py-20'>
          <div className=' lg:max-w-[50%]'>
            <h1 className='customHeading  text-[#01AEEF]  '>
              Unlock Your Business Potential with Strategic Planning and
              Informed Valuations
            </h1>
            {/* <p className='customPeragraph py-8 text-gray-300'>
              Tax & VAT Advisory: Navigating Compliance, Maximizing Savings
            </p> */}
            {/* <button className='customBtn'>Discover More</button> */}
          </div>
        </div>
      </div>
      <div className='bg-white overflow-hidden'>
        <div className='containers lg:flex gap-5 py-20 justify-between '>
          <div className='lg:max-w-[50%] '>
            <div className='flex justify-center items-center '>
              <div className='text-center lg:w-[90vh]'>
                <h4 className='customHeading text-[#01AEEF]  text-[2.2rem]'>
                  Business Valuations
                </h4>
                <h1
                  className=' customPeragraph text-center
            '
                >
                  Crafting Success, Assessing Worth: Your Business Journey, Our
                  Expertise.
                </h1>
                <div className='flex justify-center items-center py-5'>
                  <img
                    src={underLine}
                    alt='under-line'
                  />
                </div>
              </div>
            </div>
            <p className='customPeragraph py-8'>
              Our valuation professionals offer substantial expertise in
              business valuations of private held companies in a wide variety of
              industries. Whether it is for financial reporting, purchase or
              sale of a business, mergers, buy/sell agreements, divestiture and
              spin-offs, corporate carve- outs, corporate re-organizations or
              succession planning, Company Care & Associatesprepares
              comprehensive valuation for a variety of businesses regardless of
              size and structure.
            </p>
            {/* <button className='customBtn'>Read More</button> */}
          </div>
          <div className=''>
            <div className='flex justify-center items-center py-5 '>
              <img
                src={business2}
                alt='about-company'
                className='lg:w-[92%] md:w-2/4 w-3/4 object-contain animated-image-right'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white overflow-hidden'>
        <div className='containers lg:flex gap-5 pb-20 justify-between '>
          <div className=''>
            <div className='flex justify-center items-center py-5 '>
              <img
                src={business3}
                alt='about-company'
                className='lg:w-[92%] md:w-2/4 w-3/4 object-contain '
              />
            </div>
          </div>
          <div className='lg:max-w-[50%] '>
            <div className='flex justify-center items-center '>
              <div className='text-center w-[90%]'>
                <h4 className='customHeading text-[#01AEEF]  text-[2.2rem]'>
                  Business Plans
                </h4>
                <h1 className=' customPeragraph text-center'>
                  Strategic Business Planning: Your Path to Success
                </h1>
                <div className='flex justify-center items-center py-5'>
                  <img
                    src={underLine}
                    alt='under-line'
                  />
                </div>
              </div>
            </div>
            <p className='customPeragraph py-8'>
              Our advisory services team specializes in preparing professional
              business plans for a wide variety of industries and businesses.
              Our business plans are widely accepted by Manufacturing, Trading,
              Service provider and e-commerce and others. Whether you want to
              raise capital, secure a loan or pitch to an investor, Company Care
              & Associates can assist you in your efforts to prepare a business
              plan and financial models.
            </p>
            <Link to='/contact'>
              {' '}
              <button className='customBtn my-5 px-10'>Contact Us</button>{' '}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessPlan;
