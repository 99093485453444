import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/home/logo.png';
const FooterSection = () => {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <div className=''>
        <div className='bg-[#E1F0FF] mb-[1px]'>
          <div className='lg:flex containers justify-between items-top py-14 '>
            <div className='mb-5 flex justify-center'>
              {' '}
              <img
                src={logo}
                alt='logo'
                className='w-48 object-contain'
              />{' '}
            </div>
            <div className='mb-5 '>
              <ul className='text-center'>
                <li className='customPeragraphTwo text-center tracking-widest'>
                  HELP
                </li>

                <li>
                  <NavLink
                    to='/'
                    onClick={() => scrollToSection('home')}
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='/'
                    onClick={() => scrollToSection('about_section')}
                  >
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='/'
                    onClick={() => scrollToSection('contact')}
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className='mb-5'>
              <ul className='text-center'>
                <li className='customPeragraphTwo tracking-widest text-center'>
                  GET IN TOUCH
                </li>
                <li className=''>
                  <span className='customPeragraphTwo font-semibold'>
                    Call :
                  </span>{' '}
                  +8801969500600
                </li>
                <li>
                  <span className='font-semibold customPeragraphTwo'>
                    Email :
                  </span>{' '}
                  Companycarebd@gmail.com
                </li>
                {/* <li>icon</li> */}
              </ul>
            </div>
            {/* <div>
              <ul>
                <li className=' customPeragraphTwo'>DOWNLOAD OUR APP</li>
                <li>
                  <div className='flex gap-5 py-5'>
                    <img
                      src={playStore}
                      alt='playStore'
                    />
                    <img
                      src={appStore}
                      alt='appStore'
                    />
                  </div>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <div className='flex justify-center items-center bg-[#EFF7FF] py-2'>
          <p>
            © All rights reserved and designed by{' '}
            <span className='customPeragraphTwo text-[0.9rem]'>
              <a href='https://www.suffixit.com/'>Suffix IT Limited</a>
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default FooterSection;
