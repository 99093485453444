import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import underLine from '../assets/home/underLine.png';
import accounting from '../assets/service/financebanner.svg';
const RaisingBank = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contentData = [
    {
      name: 'Custom-made Financing Solutions',
      content:
        'We understand that each business has unique financial needs. Our team works closely with you to assess your requirements and modify financing solutions that align with your growth objectives.',
    },
    {
      name: 'Comprehensive Financial Analysis',
      content:
        'Before approaching banks, it,s crucial to have a solid financial foundation. Our experts conduct a comprehensive financial analysis, ensuring your business is well-prepared for the scrutiny of potential lenders.',
    },
    {
      name: 'Strategic Proposal Development',
      content:
        'Crafting a compelling proposal is the key to securing bank finance. We assist in the development of strategic and persuasive proposals that clearly communicate your business goals, financial health, and repayment strategies.',
    },
    {
      name: 'Relationship Building with Financial Institutions',
      content:
        'Establishing strong relationships with banks is essential. Our team leverages industry connections and expertise to connect you with the right financial institutions, enhancing your chances of securing favourable terms.',
    },
  ];

  return (
    <>
      <style>
        {`
          @keyframes slideLeftToRight {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(0);
            }
          }
        `}
      </style>
      <div className=' bg-[#E1F0FF] overflow-hidden'>
        <div className='containers lg:flex justify-between items-center  py-20'>
          <div className='lg:max-w-[50%]'>
            <h1 className='customHeading'>Raising Bank Finance</h1>
            <p className='customPeragraph py-8'>
              Accessing the right financial resources is pivotal for the growth
              and sustainability of your business. Our "Raising Bank Finance"
              service at Company Care & Associates is designed to guide you
              through the intricate process of securing funding from banks and
              financial institutions.
            </p>
            {/* <button className='customBtn'>Discover More</button> */}
          </div>
          <div className='flex justify-center'>
            <img
              className='lg:w-4/5 md:w-2/4 w-3/4 object-contain animated-image'
              src={accounting}
              alt='cover-image'
            />
          </div>
        </div>
      </div>
      <div className='bg-[#EFF7FF] overflow-hidden'>
        <div className='containers lg:flex gap-5 py-20 justify-between '>
          <div className=''>
            <div className='flex justify-center items-center '>
              <div className='text-center lg:w-[90vh]'>
                <h4 className='customHeading text-[#01AEEF]'>Key Features</h4>

                <div className='flex justify-center items-center py-5'>
                  <img
                    src={underLine}
                    alt='under-line'
                  />
                </div>
              </div>
            </div>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-10 py-5'>
              {contentData?.map((item, index) => (
                <div className='bg-white p-10 rounded-md border-2 border-[#01AEEF]'>
                  <p className='customPeragraph text-left pb-4 font-medium'>
                    {item?.name}
                  </p>
                  <p className='text-base'>{item?.content}</p>
                </div>
              ))}
            </div>
            <Link to='/contact' className='flex justify-center'>
              {' '}
              <button className='customBtn my-8 px-10'>Contact Us</button>{' '}
            </Link>
            {/* <button className='customBtn'>Read More</button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default RaisingBank;
