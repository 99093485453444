import emailjs from '@emailjs/browser';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import contact from '../assets/home/contact.png';
import InputField from '../common/InputField';
import SelectField from '../common/SelectField';

const ContactSection = () => {
  const { register, handleSubmit, errors } = useForm();
  const form = useRef();
  const onSubmit = (e) => {
    e.preventDefault();

    try {
      emailjs.sendForm(
        'service_kz4e14i',
        'template_kucic3d',
        e.target,
        '1s43rq_w-8noUfP6Q'
      );

      toast.success('Your Email has been sent successfully !', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      // console.log('successfully send');
    } catch (error) {
      toast.error('Sorry,your mail failed to be sent successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }

    e.target.reset();
  };

  const servicesData = [
    { id: 1, service: 'Accounting & Compilation' },
    { id: 2, service: 'Outsourced CFO Services' },
    { id: 3, service: 'TAX & VAT Advisory' },
    { id: 4, service: 'Product Costing' },
    { id: 5, service: 'Business Plans' },
    { id: 6, service: 'Market Research ' },
    { id: 7, service: 'Internal Audit Implementation' },
    { id: 8, service: 'Management Consultancy' },
    { id: 9, service: 'Financial & commercial Due Diligence' },
    { id: 10, service: 'RJSC Advisory ' },
    { id: 11, service: 'Raising Bank Finance' },
    { id: 12, service: 'Fund Management' },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className='bg-[#EFF7FF]'
      id='contact'
    >
      <ToastContainer />

      <div className='containers'>
        <div className='lg:flex justify-between py-20'>
          <div>
            <h1 className='customHeadingTwo text-black'>Have any Questions?</h1>
            <h1 className='customHeading text-[#01AEEF]'>Get in touch</h1>
            <div className='flex justify-center py-10'>
              <img
                className=' w-2/4'
                src={contact}
                alt='contact'
              />
            </div>
          </div>
          <div>
            <form
              ref={form}
              // onSubmit={handleSubmit(onSubmit)}
              onSubmit={onSubmit}
            >
              <div className=' mx-auto max-w-3xl px-5'>
                <div className='flex gap-5'>
                  <InputField
                    label='Name'
                    name='name'
                    type='text'
                    // inputRef={register}
                    placeholder='Enter Your Name'
                    isRequired
                    error={errors}
                  />
                  <InputField
                    label='Business Name'
                    name='businessName'
                    type='text'
                    // inputRef={register}
                    placeholder='Your Business Name'
                    error={errors}
                  />
                </div>
                <InputField
                  label='Email'
                  name='email'
                  type='email'
                  // inputRef={register}
                  placeholder='Enter Your Email'
                  isRequired
                  error={errors}
                />
                <InputField
                  label='Phone'
                  name='phone'
                  type='number'
                  // inputRef={register}
                  placeholder='Enter Your Phone'
                  isRequired
                  error={errors}
                />
                <SelectField
                  isRequired
                  label='Which Service You Want'
                  title='Area Of Interest'
                  items={servicesData}
                  showValue='service'
                  name='service'
                  type='text'
                  // inputRef={register}
                  value='service'
                  // error={errors}
                />
                <InputField
                  textmessage
                  label='Message'
                  name='message'
                  type='text'
                  // inputRef={register}
                  placeholder='Enter Your Thought'
                  error={errors}
                />

                <div className='flex justify-end py-5'>
                  <button
                    type='submit'
                    className='customBtn px-10'
                  >
                    SEND MESSAGE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
