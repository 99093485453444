import assessment from '../assets/home/assessment.png';
import client from '../assets/home/client.png';
import continuous from '../assets/home/continuous.png';
import implementation from '../assets/home/implementation.png';
import initial from '../assets/home/initial.png';
import workIcon from '../assets/home/workIcon.png';

export const WorkFlowData = [
  {
    image: initial,
    title: 'Initial Consultation',
    content:
      'We begin with an in-depth consultation to understand your requirements, goals, and challenges. This stage is crucial in identifying the right services and strategies for your financial success.',
  },
  {
    image: assessment,
    title: 'Assessment and Planning',
    content:
      'After the initial consultation, our team conducts a thorough needs assessment, analyzing your specific needs and financial landscape. We create a personalized plan that aligns with your objectives.',
  },
  {
    image: workIcon,
    title: 'Customize Solutions',
    content:
      'Based on the assessment and planning, we develop customized solutions, utilizing our expertise and resources to craft strategies that address your accounting, costing, consulting, auditing, or Tax advisory needs.',
  },
  {
    image: implementation,
    title: 'Implementation & Execution',
    content:
      'Once the Specific solutions are finalized and approved, we move swiftly to implement them with precision. Our experienced professionals work diligently to ensure accurate integration and execution.',
  },
  {
    image: continuous,
    title: 'Continuous Monitoring & Evaluation',
    content:
      'Our commitment extends beyond implementation. We continually monitor the performance of the implemented strategies, making necessary adjustments to ensure they remain effective and aligned with your evolving goals.',
  },
  {
    image: client,
    title: 'Client Engagement and Feedback',
    content:
      'We value your feedback and actively seek your input throughout our engagement. Your satisfaction and success are our top priorities, and we make sure to incorporate your insights into our ongoing processes.',
  },
];
