import React, { useEffect, useState } from "react";
import samplePDF from "./../assets/documents/aboutcompany.pdf";
import { Document, Page } from "react-pdf";
import { ClipLoader } from "react-spinners";
import { FaFileDownload } from "react-icons/fa";

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const AboutUs = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  // Page Scroll Up
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="my-6">
      <div className="flex justify-center pb-8 min-h-[80vh]">
        {/*  */}
        <div className="overflow-auto">
          <div className="p-5 text-sm font-[600] flex justify-between items-center">
            <button
              onClick={() => {
                window.open("/aboutcompany.pdf", "_blank");
              }}
              className=" flex gap-2 items-center border border-[#01AEEF] text-[#01AEEF] text-xs px-3 py-2 rounded-full"
            >
              <span>
                <FaFileDownload className="" />
              </span>
              DOWNLOAD
            </button>
            <nav className="flex justify-center gap-4">
              <button className="bg-primary" onClick={goToPrevPage}>
               {"<<"} Prev
              </button>
              <button className="bg-primary" onClick={goToNextPage}>
                Next {">>"}
              </button>
            </nav>
            <p>
              Page <span className="text-[#01AEEF]">{pageNumber}</span> of{" "}
              <span className="text-[#01AEEF]">{numPages}</span>
            </p>
          </div>
          <Document
            file={"/aboutcompany.pdf"}
            onLoadSuccess={onDocumentLoadSuccess}
            className="md:w-[900px] py-2"
            loading={
              <span className="flex justify-center">
                <ClipLoader color="#01AEEF" size={18} loading={true} />
              </span>
            }
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
