import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import underLine from '../assets/home/underLine.png';
import costing from '../assets/service/costing.png';
import costing2 from '../assets/service/costing2.png';
const ProductCosting = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        className=' bg-[#E1F0FF] overflow-hidden lg:py-10'
        style={{
          backgroundImage: `url(${costing})`,

          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right',
          backgroundSize: 'contain',
        }}
      >
        <div className='containers lg:flex justify-between items-center  py-20'>
          <div className='xl:max-w-[50rem] lg:max-w-[35rem]'>
            <h1 className='customHeading  text-[#01AEEF]'>
              Unveiling the True Cost: Your Path to Informed Financial Success
            </h1>
            {/* <p className='customPeragraph py-8 text-gray-500'>
              Tax & VAT Advisory: Navigating Compliance, Maximizing Savings
            </p> */}
            {/* <button className='customBtn'>Discover More</button> */}
          </div>
        </div>
      </div>
      <div className='bg-white overflow-hidden'>
        <div className='containers lg:flex gap-5 py-20 justify-between '>
          <div
            className=''
            // style={{
            //   backgroundImage: `url(${ornament}), url(${ornament})`,
            //   backgroundRepeat: 'no-repeat, no-repeat',
            //   backgroundPosition: 'left top, right bottom',
            //   backgroundSize: 'auto, auto',
            // }}
          >
            <div className='flex justify-center items-center py-5 '>
              <img
                src={costing2}
                alt='about-company'
                className='lg:w-[92%] w-[80%] object-contain animated-image-right'
              />
            </div>
          </div>
          <div className=' lg:max-w-[50%]'>
            <div className='flex justify-center items-center '>
              <div className='text-center lg:w-[90%]'>
                <h4 className='customHeading text-[#01AEEF]  text-[2.2rem]'>
                  Product Costing
                </h4>
                <h1
                  className=' customPeragraph text-center
            '
                >
                  Costing Redefined: Precision for Profitability"
                </h1>
                <div className='flex justify-center items-center py-5'>
                  <img
                    src={underLine}
                    alt='under-line'
                  />
                </div>
              </div>
            </div>
            <p className='customPeragraph py-8'>
              Precision in Profitability. Our expert analysis ensures accurate
              assessment and optimization of production costs, empowering your
              business with strategic insights to maximize profitability and
              efficiency. In today's competitive business landscape,
              understanding the cost of producing your goods isn't just a
              necessity; it's a strategic advantage. Our Product Costing Service
              at Company Care & Associates is tailored to provide you with a
              granular view of your product's financial anatomy.
            </p>
            <Link to='/contact'>
              {' '}
              <button className='customBtn my-5 px-10'>Contact Us</button>{' '}
            </Link>
            {/* <button className='customBtn'>Read More</button> */}
          </div>
        </div>
      </div>
      <div className='containers lg:flex justify-between items-center  pb-20'>
        <div>
          <div>
            <p className='customPeragraph py-8 font-medium'>
              The Power of Meticulous Analysis
            </p>
            <p className='text-base'>
              At the core of our service is a team of experts dedicated to
              meticulously analyzing every aspect of your production process. We
              break down the costs, from the raw materials to the labor and
              overheads, ensuring that no cost is overlooked. This analytical
              depth allows us to provide you with a precise cost per unit,
              granting you invaluable insights.
            </p>
          </div>
          <div>
            <p className='customPeragraph py-8 font-medium'>
              Accurate Insights for Informed Decisions{' '}
            </p>
            <p className='text-base'>
              We believe in empowering our clients with accurate data for better
              decision-making. The insights derived from our detailed cost
              analysis enable you to make informed choices regarding pricing,
              production strategies, and resource allocation. With this
              knowledge, you can align your business goals with financial
              realities
            </p>
          </div>
          <div>
            <p className='customPeragraph py-8 font-medium'>
              Efficiency through Optimization
            </p>
            <p className='text-base'>
              One of the primary outcomes of our service is the identification
              of areas for cost optimization. We work with you to develop
              strategies that reduce expenditures without compromising on
              quality or efficiency. This fine balance is where profitability
              thrives, and our service ensures you find it.
            </p>
          </div>
          <div>
            <p className='customPeragraph py-8 font-medium'>
              Steering Towards Growth
            </p>
            <p className='text-base'>
              In the fast-paced world of business, staying ahead requires a
              clear understanding of your financial landscape. Our Product
              Costing Service doesn't just provide numbers; it provides a
              roadmap to profitability and growth. By navigating the intricacies
              of product costing, we help you steer your business towards
              sustainable success. Join us at Company Care & Associates, and
              let's unlock the true potential of your products, enhancing your
              financial strategy and propelling your business towards
              prosperity.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCosting;
