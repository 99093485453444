export const taxvatserviceData = [
  {
    id: 1,
    title: `Preparation and submission of Company’s income tax return,
        attending hearing at DCT’s level from time to time and
        finalization of Income Tax assessment`,
  },
  {
    id: 2,
    title: `Expatriate employee’s Salary tax management and finalization
      for each employee.`,
  },
  {
    id: 3,
    title: `Local employee’s salary tax management and finalization for
      each employee.`,
  },
  {
    id: 4,
    title: `Advising on Individual & Company tax issues, Tax Planning
      including withholding taxes and any changes in tax rules
      regulation and applicable law and regulation in Bangladesh`,
  },
  {
    id: 5,
    title: `VAT registration`,
  },
  {
    id: 6,
    title: `Monthly VAT returns preparation, submission & maintenance of
      all documentation.`,
  },
  {
    id: 7,
    title: `Tax Due Diligence (TDD)/ Complete tax compliance review / Tax
      Planning`,
  },
  {
    id: 8,
    title: `Deferred Tax Computation and application`,
  },
  {
    id: 9,
    title: `Others Tax & VAT issues including Appeal, Tribunal ETC.`,
  },
  {
    id: 10,
    title: `Tax Clearance of Liaison Office`,
  },
];
