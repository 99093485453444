import React from 'react';
import { CgDanger } from 'react-icons/cg';
const SelectField = ({
  title = '',
  items = [],
  label = '',
  name = '',
  inputRef = '',
  isRequired = false,
  error = '',
  onChange = null,
  value = '',
  showValue = '',
  defaultValue = '',
}) => {
  return (
    <>
      <div className=''>
        <div className=''>
          <label className='  mb-2 text-[13px] font-medium text-blue-gray-800 '>
            {title}{' '}
            {isRequired ? <font className='text-red-400'>*</font> : <> </>}
          </label>
        </div>

        <div className='col-span-4'>
          <select
            required={isRequired}
            id='countries'
            name={name}
            value={defaultValue?.[value]}
            onChange={onChange}
            // ref={inputRef({ required: isRequired })}
            className=' bg-gray-50 border border-[#01AEEF]  text-gray-900 text-[13px] rounded   block w-full p-2.5    '
          >
            <option value=''>{label || 'Please choose an option'} </option>
            {items?.length > 0
              ? items?.map((item, index) => (
                  <option
                    key={index}
                    value={item?.[value]}
                  >
                    {item?.[showValue]}
                  </option>
                ))
              : []}
          </select>
          {!error && (
            <div className='text-red-400 text-[12px] font-[500] py-4'></div>
          )}
          {error[name] && (
            <div className='text-red-400 text-[12px] font-[500] py-2'>
              <p className='flex items-center '>
                <CgDanger size={13} /> This field is required
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectField;
