import React, { useEffect, useState } from "react";
import underLine from "../assets/home/underLine.png";
import { WorkFlowData } from "../common/WorkFlowData";
import WorkCard from "./WorkCard";
// Import Swiper styles
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./../App.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const WorkPage = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="bg-[#EFF7FF]" id="work">
      <div className="containers py-20">
        <div className="flex justify-center items-center ">
          <div className="text-center lg:w-[90vh]">
            <h4 className="customHeading text-[#01AEEF]">How We Work</h4>
            <h1
              className=" customPeragraph text-center
            "
            >
              At Company Care & Associates, we pride ourselves on a systematic
              and client-centric approach that ensures optimal outcomes for
              every engagement. Our work process is designed to understand,
              collaborate, and deliver Specific solutions that meet your unique
              financial needs.
            </h1>
            <div className="flex justify-center items-center py-5">
              <img src={underLine} alt="under-line" />
            </div>
          </div>
        </div>

        <Swiper
          style={{
            "--swiper-navigation-color": "#01AEEF",
            "--swiper-navigation-size": "30px",
          }}
          slidesPerView={
            screenSize > 1200
              ? 3
              : screenSize > 1000
              ? 3
              : screenSize > 800
              ? 2
              : screenSize > 600
              ? 1
              : 1
          }
          centeredSlides={true}
          spaceBetween={30}
          freeMode={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: true,
          }}
          loop={true}
          // pagination={{
          //   clickable: true,
          // }}

          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="px-10"
        >
          {WorkFlowData?.map((item, index) => (
            <SwiperSlide className="bg-transparent" key={index}>
              {" "}
              <WorkCard
                key={index}
                image={item?.image}
                title={item?.title}
                content={item?.content}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default WorkPage;
