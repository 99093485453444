import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import underLine from '../assets/home/underLine.png';
import accounting from '../assets/service/accounting.png';
import compilation from '../assets/service/compilation.png';
const AccountingCompilation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <style>
        {`
          @keyframes slideLeftToRight {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(0);
            }
          }
        `}
      </style>
      <div className=' bg-[#E1F0FF] overflow-hidden'>
        <div className='containers lg:flex justify-between items-center  py-20'>
          <div className='lg:max-w-[50%]'>
            <h1 className='customHeading'>
              Elevate Your Finances: Accounting & Compilation Excellence
            </h1>
            <p className='customPeragraph py-8'>
              Discover precision in financial records with our Accounting &
              Compilation Services. Trust our experts to organize, streamline,
              and optimize your financial data, empowering your business to
              reach new heights.
            </p>
            {/* <button className='customBtn'>Discover More</button> */}
          </div>
          <div className='flex justify-center'>
            <img
              className='lg:w-4/5 md:w-2/4 w-3/4 object-contain animated-image'
              src={accounting}
              alt='cover-image'
            />
          </div>
        </div>
      </div>
      <div className='bg-[#EFF7FF] overflow-hidden'>
        <div className='containers lg:flex gap-5 py-20 justify-between '>
          <div className=''>
            <div className='flex justify-center items-center py-5 '>
              <img
                src={compilation}
                alt='about-company'
                className='lg:w-[92%] md:w-2/4 w-3/4 object-contain animated-image-left'
              />
            </div>
          </div>
          <div className='lg:max-w-[50%]'>
            <div className='flex justify-center items-center '>
              <div className='text-center lg:w-[90vh]'>
                <h4 className='customHeading text-[#01AEEF]'>
                  Accounting & Compilation
                </h4>
                <h1
                  className=' customPeragraph text-center
            '
                >
                  Unlock Financial Clarity: Where Precision Meets Insight
                </h1>
                <div className='flex justify-center items-center py-5'>
                  <img
                    src={underLine}
                    alt='under-line'
                  />
                </div>
              </div>
            </div>
            <p className='customPeragraph py-8'>
              Company Care & Associates offers individuals and businesses a
              variety of accounting services tailored to their special needs. We
              take pride in providing one of the most focused and quality
              accounts outsourcing services in Bangladesh to small and medium
              enterprises. Our accounting team consists of young hand-picked
              accounting professionals who are qualified, skilled and proactive.
              We are flexible in our approach, and can work either on-site or
              off- site to cater to the client’s requirement at a fraction of
              the cost when compared to a full- time employee.
            </p>
            <Link to='/contact'>
              {' '}
              <button className='customBtn my-5 px-10'>Contact Us</button>{' '}
            </Link>
            {/* <button className='customBtn'>Read More</button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountingCompilation;
