import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import AccountingCompilation from './components/AccountingCompilation';
import AllServiceCard from './components/AllServiceCard';
import BusinessPlan from './components/BusinessPlan';
import ContactSection from './components/ContactSection';
import FinancialDiligence from './components/FinancialDiligence';
import FooterSection from './components/FooterSection';
import FundManagement from './components/FundManagement';
import InternalAudit from './components/InternalAudit';
import ManagementConsultancy from './components/ManagementConsultancy';
import MarketResearch from './components/MarketResearch';
import NavbarSection from './components/NavbarSection';
import OutsourcedServices from './components/OutsourcedServices';
import ProductCosting from './components/ProductCosting';
import RaisingBank from './components/RaisingBank';
import RjscAdvisory from './components/RjscAdvisory';
import TaxvatAdvisory from './components/TaxvatAdvisory';
import HomeScreen from './screens/HomeScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import AboutUs from './screens/AboutUs';
function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const routes = [
    {
      path: '/',
      components: HomeScreen,
    },
    {
      path: '/*',
      components: NotFoundScreen,
    },
    {
      path: '/about',
      components: AboutUs
    },
    {
      path: '/all-services',
      components: AllServiceCard,
    },
    {
      path: '/accounting-compilation',
      components: AccountingCompilation,
    },
    {
      path: '/outsourced-CFO',
      components: OutsourcedServices,
    },
    {
      path: '/taxVat-advisory',
      components: TaxvatAdvisory,
    },
    {
      path: '/product-costing',
      components: ProductCosting,
    },
    {
      path: '/business-plan',
      components: BusinessPlan,
    },
    {
      path: '/marketResearch-feasibility',
      components: MarketResearch,
    },
    {
      path: '/internal-audit-implementation',
      components: InternalAudit,
    },
    {
      path: '/rjsc-advisory',
      components: RjscAdvisory,
    },
    {
      path: '/raising-bank-finance',
      components: RaisingBank,
    },
    {
      path: '/financial-and-commercial-diligence',
      components: FinancialDiligence,
    },
    {
      path: '/management-consultancy',
      components: ManagementConsultancy,
    },
    {
      path: '/fund-management',
      components: FundManagement,
    },

    {
      path: '/contact',
      components: ContactSection,
    },
  ];
  return (
    <div className=''>
      <NavbarSection />
      <Routes>
        {routes?.map((r, index) => {
          return (
            <Route
              key={index}
              path={r?.path}
              element={<r.components />}
            />
          );
        })}
      </Routes>
      {/* <HomeScreen /> */}

      <FooterSection />
    </div>
  );
}

export default App;
